import React from "react";
import theme from "theme";
import { Theme, Link, Image, Section, Text, Box, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About - DataLeak.us
			</title>
			<meta name={"description"} content={"dataleaks.us is a non-profit organization that watches data breach events around the world, Helping people know and protect their properties."} />
			<meta property={"og:title"} content={"About - Dataleaks.us"} />
			<meta property={"og:description"} content={"dataleaks.us is a non-profit organization that watches data breach events around the world, Helping people know and protect their properties."} />
			<link rel={"shortcut icon"} href={"https://i.imgur.com/eGfhJiA.png"} type={"image/x-icon"} />
		</Helmet>
		<Section quarkly-title="Header" align-items="center" justify-content="center" padding="16px 0 16px 0">
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
			/>
			<Link position="relative" transition="opacity 200ms ease" quarkly-title="Link" href="/">
				<Image
					z-index="3"
					src="https://uploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00:59:29.119Z"
					width="125px"
					srcSet="https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Link>
			<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end">
				<Override
					slot="icon"
					icon={MdMenu}
					size="36px"
					md-right="0px"
					md-position="relative"
					category="md"
				/>
				<Override
					slot="menu-open"
					md-justify-content="center"
					md-top={0}
					md-bottom={0}
					md-display="flex"
					md-flex-direction="column"
					md-align-items="center"
				/>
				<Override slot="icon-open" md-right="18px" md-position="fixed" md-top="18px" />
				<Override
					slot="menu"
					md-height="100%"
					justify-content="center"
					lg-transition="transform 400ms ease 0s"
					md-position="fixed"
					display="flex"
					md-top={0}
					md-width="100%"
					lg-transform="translateY(0px) translateX(0px)"
					md-left={0}
					padding="0px 0 0px 0"
					align-items="center"
				>
					<Override slot="item-index" lg-display="none" display="none" />
					<Override
						slot="link"
						hover-opacity="1"
						md-transition="opacity .15s ease 0s"
						md-font="16px/24px sans-serif"
						font="--base"
						color="--dark"
						opacity=".5"
						transition="opacity .15s ease 0s"
						text-transform="initial"
						md-color="--dark"
						letter-spacing="0.5px"
						md-opacity=".5"
						md-hover-opacity="1"
						md-active-opacity="1"
						text-decoration-line="initial"
					/>
					<Override
						slot="link-active"
						opacity="1"
						color="--primary"
						cursor="default"
						md-opacity="1"
						md-cursor="default"
					/>
					<Override
						slot="item"
						text-align="center"
						padding="8px 20px 8px 20px"
						md-padding="16px 40px 16px 40px"
						display="inline-block"
						text-transform="uppercase"
					/>
					<Override slot="item-404" lg-display="none" display="none" />
				</Override>
				<Override slot="icon,icon-close" category="md" icon={MdMenu} />
			</Components.BurgerMenu>
			<Link
				href="/contact"
				md-display="none"
				white-space="nowrap"
				color="--light"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				font="--base"
				letter-spacing="0.5px"
				z-index="5"
				background="--color-primary"
				padding="8px 18px 8px 18px"
				border-radius="0px"
				hover-transform="translateY(-4px)"
				justify-self="end"
				text-decoration-line="initial"
			>
				Contact Us
			</Link>
		</Section>
		<Section quarkly-title="Hero" padding="25px 0 75px 0" lg-padding="25px 0 25px 0" justify-content="center">
			<Override
				slot="SectionContent"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
			/>
			<Box
				align-items="center"
				display="flex"
				justify-content="center"
				background="radial-gradient(at center,--color-darkL2 23.3%,rgba(0,0,0,0) 82.4%),linear-gradient(180deg,rgba(155, 108, 252, 0.15) 0%,transparent 100%) 0 0 no-repeat,--color-greyD1"
				md-min-height="360px"
				sm-min-height="180px"
				width="100%"
				min-height="480px"
			>
				<Text
					font="--headline1"
					margin="0px 0px 0px 0px"
					quarkly-title="Title"
					sm-text-align="center"
					text-align="center"
					md-font="--headline2"
					display="flex"
					align-items="center"
					color="--light"
					justify-content="center"
					sm-font="normal 900 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					lg-font="--headline2"
				>
					About Us.
				</Text>
			</Box>
		</Section>
		<Section
			padding="80px 0px 80px 0px"
			quarkly-title="About"
			background="--color-lightD1"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
			box-sizing="border-box"
			border-width="1px 0px 1px 0px"
			border-style="solid"
			border-color="--color-lightD2"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Box
				display="grid"
				grid-gap="32px"
				grid-template-columns="repeat(3, 1fr)"
				width="100%"
				lg-grid-template-columns="1fr"
			>
				<Text
					color="--darkL2"
					lg-margin="0px 0px 0px 0px"
					md-font="--lead"
					font="--headline3"
					margin="0px 0px 10px 0px"
					display="inline-block"
				>
					Dataleaks.us
				</Text>
				<Text
					display="inline-block"
					opacity="0.6"
					color="--darkL2"
					lg-text-align="left"
					text-align="left"
					font="--base"
					margin="0px 0px 0px 0px"
				>
					we care about keeping your online information safe. We started this because we saw how often personal details were getting exposed on the internet. Our team has built a tool that checks for these data leaks and lets you know if your information is out there.{" "}
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					display="inline-block"
					opacity="0.6"
					color="--darkL2"
					lg-text-align="left"
					text-align="left"
					font="--base"
				>
					But we don't stop there. We also help by teaching you how to stay safe online and what to do if your details get leaked. We're here to help and make the online world a bit safer for everyone. Trusting us with your online safety is a big deal, and we promise to always do our best to protect you.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-light">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--dark" font="--headline2" md-margin="0px 0px 30px 0px">
					How to protect your self?
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--darkL1" font="--base" border-color="#000000">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Data leaks
					</Strong>
					{" "}are happening in our world single day, Millions of sensitive personal information are being leaked to the public. It can be dangerous to many people as we have many accounts like a bank, email, streaming, or gaming accounts linked to that information. In this guide, We will teach you the ways you can protect yourself from data breaches, or what should you do if your data is already leaked.
					<br />
					<br />
					<Strong>
						Did you come here because of an email from us? If so, your details were found in a recent data leak. Here's what to do right away:
					</Strong>
					<br />
					<br />
					<Strong>
						-
					</Strong>
					{"  "}Change passwords on all accounts that use the same email and password combo that was sent to your email
					<br />
					<Strong>
						-
					</Strong>
					{" "}Turn on 2-factor authentication (2FA) for extra safety.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--dark" font="--base">
					You may be pondering the mechanisms that lead to such disconcerting breaches of personal data. Predominantly, these lapses occur when online platforms, where you've willingly shared your information, fail to uphold robust security measures. Sophisticated hackers, with their evolving tactics, exploit these vulnerabilities, harvesting vast amounts of data in the process.
					<br />
					<br />
					Emphasizing the imperative nature of digital security, recent statistics are quite revealing. In the preceding year, a staggering number of individuals reported considerable financial losses owing to fraudulent activities and identity theft. With the technological prowess at their disposal, hackers are becoming increasingly adept at pursuing their most coveted asset: your passwords. Should they successfully procure yours, the repercussions could range from them depleting your financial reserves to manipulating your identity, establishing unauthorized accounts in your name.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Strategies for Fortifying Your Digital Passwords
					</Strong>
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Intricacy is Key
					</Strong>
					: Envision your password as a labyrinthine enigma, designed to perplex and deter potential intruders. The more convoluted and multi-faceted your password, the more insurmountable it becomes for hackers to decode.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Engage Two-Factor Authentication (2FA)
					</Strong>
					: Think of 2FA as an advanced security checkpoint for your digital domains. Beyond the rudimentary password, it necessitates an auxiliary verification step, such as a dynamically generated code relayed to your mobile device, fortifying your defense mechanisms.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Diversify Your Digital Keys
					</Strong>
					: It's paramount to refrain from using a uniform password across multiple platforms. In the unfortunate event of one platform being compromised, having unique passwords ensures that the sanctity of your other accounts remains untainted.
				</Text>
			</Box>
		</Section>
		<Section padding="50px 0 40px 0" quarkly-title="Footer-12">
			<Box
				display="grid"
				lg-width="100%"
				flex-direction="row"
				lg-flex-direction="row"
				justify-content="space-between"
				width="100%"
				padding="0 0px 0 0px"
				lg-padding="0 0px 50px 0px"
				margin="0px 0px 50px 0px"
				md-flex-wrap="wrap"
				md-padding="0 0px 0 0px"
				md-margin="0px 0px 40px 0px"
				grid-template-columns="repeat(3, 1fr)"
				lg-align-items="start"
				grid-gap="36px 34px"
				md-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					align-items="flex-start"
					lg-align-items="flex-start"
					justify-content="flex-start"
					display="grid"
					lg-flex-direction="column"
					flex-direction="column"
					flex-wrap="wrap"
					align-content="start"
					grid-gap="10px 0"
					lg-justify-content="start"
				>
					<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.5 --fontFamily-sans" md-margin="0px 0px 10px 0px">
						Menu
					</Text>
					<Link
						border-color="--color-primary"
						display="flex"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						margin="0px 0 0px 0"
						lg-border-width="0px 0px 0px 2px"
						href="/index"
						text-decoration-line="initial"
						color="--darkL1"
						hover-color="#6d32ec"
					>
						Home
					</Link>
					<Link
						margin="0px 0 0px 0"
						display="flex"
						href="/about"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						text-decoration-line="initial"
						color="--darkL1"
						hover-color="#6d32ec"
					>
						About
					</Link>
					<Link
						margin="0px 0 0px 0"
						hover-color="#6d32ec"
						href="/team"
						text-decoration-line="initial"
						color="--darkL1"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						display="flex"
					>
						Password-check
					</Link>
					<Link
						margin="0px 0 0px 0"
						hover-color="#6d32ec"
						href="https://article.dataleaks.us/"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						text-decoration-line="initial"
						color="--darkL1"
						display="flex"
					>
						Articles
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-width="100%"
					sm-padding="0px 0px 0px 0"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.5 --fontFamily-sans">
						Contact
					</Text>
					<Text margin="0px 0px 20px 0px" font="--base" color="#5a5d64">
						support@dataleaks.us
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-width="100%"
					sm-padding="0px 0px 0px 0"
					md-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.5 --fontFamily-sans">
						Dataleaks.us
					</Text>
					<Text margin="0px 0px 40px 0px" font="--base" color="#5a5d64">
						dataleaks.us is a non-profit organization that watches data breach events around the world, Helping people know and protect their properties.
					</Text>
				</Box>
			</Box>
			<Box
				display="block"
				padding="0 0px 0px 0px"
				border-color="--color-lightD2"
				md-padding="0 0px 0px 0px"
				md-flex-direction="column"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="#5a5d64"
					md-margin="0px 0px 25px 0px"
					sm-text-align="center"
					text-align="center"
					letter-spacing="1px"
				>
					© 2021-2023 Dataleaks.us  . All rights reserved.
				</Text>
			</Box>
		</Section>
	
	</Theme>;
});